import numeral from 'numeral'
import PropTypes from 'prop-types'
import React, {Component} from 'react'

export default class DataMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: false,
      degTarget: 0
    }
  }

  toggle() {
    this.setState({showMenu: !this.state.showMenu})
  }

  changeDegTarget(e) {
    this.setState({degTarget: e.target.value})
  }

  render() {
    let menuClass = this.state.showMenu ? 'deg-menu show' : 'deg-menu'

    const rad0List = []
    const rad1List = []
    const planetOptions = []
    const r = this.props.planetRenderer
    if (r) {
      const target = r.planets[this.state.degTarget].planet
      for (let idx in r.planets) {
        let p = r.planets[idx]
        planetOptions.push(
          <option value={idx} key={idx}>
            {p.name}
          </option>
        )

        let rad0 = numeral(
          (r.planets[idx].planet.getRad() * 180) / Math.PI
        ).format('0.000')
        rad0List.push(
          <span key={p.name + 'rad0'}>
            {p.name}: {rad0}°
          </span>
        )
        if (this.state.degTarget === idx) continue
        let rad1 = numeral(
          (target.getPlanetRad(r.planets[idx].planet) * 180) / Math.PI
        ).format('0.000')
        rad1List.push(
          <span key={p.name + 'rad1'}>
            {p.name}: {rad1}°
          </span>
        )
      }
    }

    return (
      <div className="bottom-navi" role="navigation">
        <div className={menuClass}>
          <div>
            <h3>From vernal equinox:</h3>
            {rad0List}
          </div>
          <div>
            <h3>
              From{' '}
              <select
                value={this.state.degTarget}
                onChange={this.changeDegTarget.bind(this)}
              >
                {planetOptions}
              </select>
              :
            </h3>
            {rad1List}
          </div>
        </div>
        <button
          className="mdl-button mdl-js-button mdl-button--fab mdl-button--colored"
          onClick={this.toggle.bind(this)}
        >
          <i className="material-icons">assessment</i>
        </button>
      </div>
    )
  }
}

DataMenu.propTypes = {
  planetRenderer: PropTypes.object,
  date: PropTypes.object.isRequired
}
