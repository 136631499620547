import React, {Component} from 'react'

export default class NavigationHeader extends Component {
  render() {
    return (
      <header className="mdl-layout__header">
        <div className="mdl-layout__header-row">
          <span className="mdl-layout-title">KIDOU</span>
        </div>
      </header>
    )
  }
}
