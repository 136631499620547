import PropTypes from 'prop-types'
import React, {Component} from 'react'
import MainContainer from '../containers/main-container'
import NavigationDrawerContainer from '../containers/navigation-drawer-container'
import NavigationHeader from './navigation-header.jsx'

export default class Root extends Component {
  render() {
    return (
      <div className="mdl-layout mdl-js-layout mdl-layout--fixed-drawer mdl-layout--fixed-header">
        <NavigationHeader />
        <NavigationDrawerContainer />
        <MainContainer />
      </div>
    )
  }
}

Root.propTypes = {
  solarSystem: PropTypes.object.isRequired
}
