import galactic from 'galactic'
import AstronomyUtils from './AstronomyUtils'

class FixedStar {
  constructor(coordinate, ly = 0, date = new Date()) {
    this.ly = ly
    this.coordinate = coordinate
    this.galactic = galactic(coordinate)
    this.setDate(date)
  }

  setDate(date) {
    this.coordinate.obliquity = AstronomyUtils.getEarthObliquity(date)
    const ecliptic = this.ecliptic()

    this.point = [
      Math.cos(ecliptic.longitude()) * Math.cos(ecliptic.latitude()),
      Math.sin(ecliptic.longitude()) * Math.cos(ecliptic.latitude()),
      -Math.sin(ecliptic.latitude())
    ]
  }

  getLy() {
    return this.ly
  }

  getAu() {
    return AstronomyUtils.lyToAu(this.ly)
  }

  ecliptic() {
    return this.galactic.ecliptic()
  }

  equatorial() {
    return this.galactic.equatorial()
  }
}

export default FixedStar
