import {EventEmitter} from 'events'
import _ from 'lodash'
import Stats from 'stats.js'
import {
  WebGLRenderer,
  Scene,
  PointLight,
  PerspectiveCamera,
  SphereGeometry,
  MeshLambertMaterial,
  MeshBasicMaterial,
  Geometry,
  Line,
  LineBasicMaterial,
  Vector3,
  Matrix4,
  Mesh,
  Frustum
} from 'three'
import Satellite from './Satellite'

class PlanetsRenderer extends EventEmitter {
  constructor (element, system, options = {}) {
    super()

    this.isHeadless = options.isHeadless || false
    this.width = options.width || 600
    this.height = options.height || 400
    this.element = element
    this.system = system
    this.planets = []
    this.fixedStars = []
    this.satellites = []
    this.zoom = options.zoom || 30
    this.zoomMode = options.zoomMode || PlanetsRenderer.ZOOM_NORMAL
    this.centerIdx = -1
    this.date = new Date()
    this.controls = null
    this.centerMode = PlanetsRenderer.CENTER_PLANET
    if (!this.isHeadless) {
      this.stats = new Stats()
    }

    this.init(options.renderer)
  }

  get2DPosition (target) {
    const vector = new Vector3()

    vector.setFromMatrixPosition(target.matrixWorld)
    vector.project(this.camera)

    let orix = ((vector.x + 1) / 2) * this.width
    let oriy = (-(vector.y - 1) / 2) * this.height
    let x = orix
    let y = oriy

    // +x
    if (orix > this.width) {
      x = this.width - 100
      y = (-(vector.y / vector.x - 1) / 2) * this.height
    }

    // -x
    if (orix < 0) {
      x = 0
      y = (-(-(vector.y / vector.x) - 1) / 2) * this.height
    }

    // y+
    if (y > this.height) {
      y = this.height - 50
      x = ((-(vector.x / vector.y) + 1) / 2) * this.width
    }

    // y-
    if (y < 0) {
      y = 0
      x = ((vector.x / vector.y + 1) / 2) * this.width
    }

    return {x: x, y: y, isVisible: this.frustum.containsPoint(target.position)}
  }

  _zoom (a) {
    if (this.zoomMode === PlanetsRenderer.ZOOM_NORMAL) {
      return this.zoom
    }
    if (a > 3) {
      return this.zoom / (Math.log(a) * 1.8)
    }

    return this.zoom
  }

  setZoomMode (zoomMode) {
    this.zoomMode = zoomMode
    for (let planet of this.planets) {
      let p = planet.planet
      let zoom = this._zoom(p.a)
      planet.mesh.position.set(
        p.point[0] * zoom,
        p.point[2] * zoom,
        p.point[1] * -zoom
      )
      planet.systemLine.geometry.vertices[1] = new Vector3(
        p.point[0] * zoom,
        p.point[2] * zoom,
        p.point[1] * -zoom
      )
      planet.systemLine.geometry.verticesNeedUpdate = true

      let geometry = new Geometry()
      let n = Math.floor(0.2 * p.a * zoom) + 30
      for (let j = 0; j <= n; j++) {
        let point = p.calcPoint((2 * Math.PI * j) / n)
        geometry.vertices.push(
          new Vector3(point[0] * zoom, point[2] * zoom, point[1] * -zoom)
        )
      }
      let ellipse = new Line(geometry, new LineBasicMaterial({color: 0xffffff}))
      this.scene.remove(planet.ellipse)
      this.scene.add(ellipse)
      planet.ellipse = ellipse
    }
  }

  init (renderer = null) {
    this.element.innerHTML = ''

    this.renderer = renderer || new WebGLRenderer({antialias: true})
    this.renderer.setClearColor(0x000000)
    if (typeof window === 'undefined') {
      this.renderer.setPixelRatio(1)
    } else {
      this.renderer.setPixelRatio(window?.devicePixelRatio || 1)
    }
    this.renderer.setSize(this.width, this.height)
    this.element.appendChild(this.renderer.domElement)

    this.scene = new Scene()

    this.camera = new PerspectiveCamera(45, this.width / this.height, 1, 10000)
    this.camera.position.set(0, 200, 200)
    this.camera.lookAt(this.scene.position)
    this.frustum = new Frustum()

    this.starSphere = new SphereGeometry(3, 16, 8)
    this.starLight = new PointLight(0xffffcc, 10, 1000)
    this.starMesh = new Mesh(
      this.starSphere,
      new MeshBasicMaterial({color: 0xdd3333})
    )
    this.starLight.add(this.starMesh)
    this.scene.add(this.starLight)

    for (let planet of this.system.planets) {
      // let radius = planet.r / 3000;
      let zoom = this._zoom(planet.a)
      let planetSphere = new SphereGeometry(2, 16, 8)
      let planetMesh = new Mesh(
        planetSphere,
        new MeshLambertMaterial({color: 0x0000ff})
      )
      planetMesh.position.set(
        planet.point[0] * zoom,
        planet.point[2] * zoom,
        planet.point[1] * -zoom
      )

      let geometry = new Geometry()
      let n = Math.floor(0.2 * planet.a * zoom) + 30
      for (let j = 0; j <= n; j++) {
        let point = planet.calcPoint((2 * Math.PI * j) / n)
        geometry.vertices.push(
          new Vector3(point[0] * zoom, point[2] * zoom, point[1] * -zoom)
        )
      }
      let ellipse = new Line(geometry, new LineBasicMaterial({color: 0xffffff}))

      let geometryLine = new Geometry()
      geometryLine.vertices.push(new Vector3(0, 0, 0))
      geometryLine.vertices.push(
        new Vector3(
          planet.point[0] * zoom,
          planet.point[2] * zoom,
          planet.point[1] * -zoom
        )
      )
      let systemLine = new Line(
        geometryLine,
        new LineBasicMaterial({color: 0xaaaaaa})
      )

      this.scene.add(planetMesh)
      this.scene.add(ellipse)
      this.scene.add(systemLine)

      let label = null
      if (!this.isHeadless) {
        label = document.createElement('div')
        label.style.position = 'absolute'
        label.style.left = '0'
        label.style.top = '0'
        label.style.color = '#FFF'
        label.appendChild(document.createTextNode(planet.name))
        this.element.appendChild(label)
      }

      this.planets.push({
        name: planet.name,
        planet,
        sphere: planetSphere,
        mesh: planetMesh,
        ellipse,
        systemLine,
        label
      })
    }

    if (!this.isHeadless) {
      this.stats.domElement.style.position = 'absolute'
      this.stats.domElement.style.top = '0px'
      this.stats.domElement.style.zIndex = 100
      this.stats.domElement.style.opacity = 0.8

      this.element.appendChild(this.stats.domElement)
    }
  }

  setControl (control) {
    this.controls = control
  }

  setCenter (id, mode = PlanetsRenderer.CENTER_PLANET) {
    if (mode === PlanetsRenderer.CENTER_SATELLITE) {
      let idx = _.findIndex(this.satellites, {id})
      if (idx !== -1) {
        this.centerIdx = idx
        this.centerMode = mode
      }
    } else {
      this.centerIdx = id
      this.centerMode = mode
    }

    this.moveCenter()
  }

  moveCenter () {
    if (this.centerIdx == -1) {
      this.scene.position.set(0, 0, 0)
      this.camera.lookAt(this.scene.position)
      return
    }

    let mesh = null
    if (this.centerMode === PlanetsRenderer.CENTER_PLANET) {
      let planet = this.planets[this.centerIdx]
      mesh = planet.mesh
    } else {
      let satellite = this.satellites[this.centerIdx]
      mesh = satellite.mesh
    }
    let vector = mesh.position.clone()

    this.scene.position.set(-vector.x, -vector.y, -vector.z)
    this.camera.lookAt(this.scene.position)
  }

  setDate (date) {
    this.date = date
    this.system.setDate(date)
    for (let planet of this.planets) {
      let p = planet.planet
      let zoom = this._zoom(p.a)
      planet.mesh.position.set(
        p.point[0] * zoom,
        p.point[2] * zoom,
        p.point[1] * -zoom
      )
      planet.systemLine.geometry.vertices[1] = new Vector3(
        p.point[0] * zoom,
        p.point[2] * zoom,
        p.point[1] * -zoom
      )
      planet.systemLine.geometry.verticesNeedUpdate = true
    }
    this.moveCenter()
    this.emit('change', this)
  }

  clockSatellite (dt) {
    for (let satellite of this.satellites) {
      let s = satellite.satellite
      s.clock(dt)
      satellite.mesh.position.set(
        s.point[0] * this.zoom,
        s.point[2] * this.zoom,
        s.point[1] * -this.zoom
      )
    }
  }

  addFixedStar (name, fixedStar) {
    fixedStar.setDate(this.date)
    let sphere = new SphereGeometry(10, 16, 8)
    let mesh = new Mesh(sphere, new MeshLambertMaterial({color: 0xffffff}))
    let starLight = new PointLight(0xffffcc, 10, 10000)
    starLight.add(mesh)

    let fixedStartZoom = fixedStar.getAu()
    starLight.position.set(
      fixedStar.point[0] * this.zoom * fixedStartZoom,
      fixedStar.point[2] * -this.zoom * fixedStartZoom,
      fixedStar.point[1] * -this.zoom * fixedStartZoom
    )

    this.scene.add(starLight)

    let label = null
    if (!this.isHeadless) {
      label = document.createElement('div')
      label.style.position = 'absolute'
      label.style.left = '0'
      label.style.top = '0'
      label.style.color = '#FFF'
      label.appendChild(document.createTextNode(name))
      this.element.appendChild(label)
    }

    this.fixedStars.push({
      name,
      fixedStar,
      light: starLight,
      mesh,
      label
    })
  }

  addSatellite (name, planet, v, rad1 = 0, rad2 = 0) {
    const s = new Satellite(this.system, name, planet, v, rad1, rad2)

    let sphere = new SphereGeometry(2, 16, 8)
    let mesh = new Mesh(sphere, new MeshLambertMaterial({color: 0x00ff00}))
    mesh.position.set(
      s.point[0] * this.zoom,
      s.point[2] * this.zoom,
      s.point[1] * -this.zoom
    )

    this.scene.add(mesh)

    let label = null
    if (!this.isHeadless) {
      label = document.createElement('div')
      label.style.position = 'absolute'
      label.style.left = '0'
      label.style.top = '0'
      label.style.color = '#FFF'
      label.appendChild(document.createTextNode(name))
      this.element.appendChild(label)
    }

    this.satellites.push({
      id: s.id,
      satellite: s,
      mesh: mesh,
      label: label
    })

    return s
  }

  resize (width, height) {
    this.width = width
    this.height = height

    this.camera.aspect = this.width / this.height
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(this.width, this.height)
  }

  moveCameraToTop () {
    this.controls?.reset()
    this.camera.up.set(0, 1, 0)
    this.camera.position.set(0, this.camera.position.y, 0.001)
  }

  render () {
    this.renderer.render(this.scene, this.camera)
  }

  animate () {
    let fn = 0
    const animate = () => {
      fn++
      window.requestAnimationFrame(animate)
      this.controls?.update()

      const mtx = new Matrix4()
      mtx.multiplyMatrices(
        this.camera.projectionMatrix,
        this.camera.matrixWorldInverse
      )
      this.frustum.setFromMatrix(mtx)

      if (fn > 1) {
        for (let objects of [this.planets, this.satellites, this.fixedStars]) {
          for (let object of objects) {
            let pos = this.get2DPosition(object.mesh)
            object.label.style.left = `${pos.x}px`
            object.label.style.top = `${pos.y}px`
            object.label.style.opacity = pos.isVisible ? 1 : 0.5
          }
        }

        fn = 0
      }

      this.render()
      this.stats?.update()
    }

    animate()
  }
}

PlanetsRenderer.CENTER_PLANET = 1
PlanetsRenderer.CENTER_SATELLITE = 2

PlanetsRenderer.ZOOM_NORMAL = 1
PlanetsRenderer.ZOOM_HELIO = 2

export default PlanetsRenderer
