import moment from 'moment'
import Julian from './Julian'

class Planet {
  /**
   * config options
   * 0:  a         軌道長半径 au
   * 1:  e         離心率
   * 2:  I         軌道傾斜角 rad
   * 3:  L         平均経度   rad
   * 4:  peri      近日点経度 rad
   * 5:  node      昇交点経度 rad
   * 6:  a_dot     軌道長半径 au/century
   * 7:  e_dot     離心率    /century
   * 8:  I_dot     軌道傾斜角 rad/century
   * 9:  L_dot     平均経度   rad/century
   * 10: peri_dot  近日点経度 rad/century
   * 11: node_dot  昇交点経度 rad/century
   * 12: b         MのT^2係数              木星から冥王星
   * 13: c         Mのcos()の係数          木星から海王星
   * 14: s         Mのsin()の係数          木星から海王星
   * 15: f         Mのcos(),sin()のTの係数 木星から海王星
   *
   * http://ssd.jpl.nasa.gov/?planet_pos
   * http://ssd.jpl.nasa.gov/txt/aprx_pos_planets.pdf
   *
   * @param name name of planet
   * @param mass 質量[kg]
   * @param r 半径[km]
   * @param config1 data for 1800AD to 2050AD
   * @param config2 data for 3000BC to 3000AD
   * @param options
   */
  constructor(name, mass, r, config1, config2, options = {}) {
    this.name = name
    this.mass = mass
    this.r = r
    this.config1 = config1
    this.config2 = config2
    this.date = options.date || new Date()
    this.setDate(this.date)
  }

  setDate(date) {
    const year = moment(date).year()
    let config = this.config2
    if (year >= 1800 && year <= 2050) {
      config = this.config1
    }

    const T = Julian.dateToJulianCentury(date)

    // 軌道要素を求める
    // https://ja.wikipedia.org/wiki/%E8%BB%8C%E9%81%93%E8%A6%81%E7%B4%A0

    // 軌道長半径[au]
    this.a = config[0] + config[6] * T
    // 離心率
    this.e = config[1] + config[7] * T
    // 軌道短半径[au]
    this.b = this.a * Math.sqrt(1 - this.e * this.e)
    // 軌道傾斜角[rad]
    this.incl = config[2] + config[8] * T
    // 平均経度[rad]
    this.longitude = config[3] + config[9] * T
    // 近日点経度[rad]
    this.varpi = config[4] + config[10] * T
    // 昇交点経度[rad]
    this.node = config[5] + config[11] * T
    // 近日点引数[rad]
    this.peri = this.varpi - this.node
    // 平均近点角M[rad]
    this.m = this.longitude - this.varpi
    if (config.length >= 13) {
      this.m += config[12] * (T * T)
      if (config.length >= 16) {
        const fT = config[13] * T
        this.m += config[13] * Math.cos(fT) + config[15] * Math.sin(fT)
      }
    }

    // 黄道面座標系への変換用行列
    const cos_I = Math.cos(this.incl)
    const sin_I = Math.sin(this.incl)
    const cos_Node = Math.cos(this.node)
    const sin_Node = Math.sin(this.node)
    const cos_Peri = Math.cos(this.peri)
    const sin_Peri = Math.sin(this.peri)
    this.convertMatrix = [
      [
        cos_Peri * cos_Node - sin_Peri * sin_Node * cos_I,
        -sin_Peri * cos_Node - cos_Peri * sin_Node * cos_I
      ],
      [
        cos_Peri * sin_Node + sin_Peri * cos_Node * cos_I,
        -sin_Peri * sin_Node + cos_Peri * cos_Node * cos_I
      ],
      [sin_Peri * sin_I, cos_Peri * sin_I]
    ]

    // ケプラーの運動方程式を解いて離心近点角E[rad]を求める。
    let deltaE = null
    this.m -=
      Math.floor((this.m + Math.PI) / (Math.PI * 2)) * (Math.PI * 2)
    this.E = this.m
    do {
      deltaE =
        (this.E - this.e * Math.sin(this.E) - this.m) /
        (1 - this.e * Math.cos(this.E))
      this.E -= deltaE
    } while (deltaE < -0.000001 || 0.000001 < deltaE)

    // 日心黄道座標 [au,au,au] を求める
    this.point = this.calcPoint(this.E)
  }

  calcPoint(E) {
    // 近日点方向をxPrime軸とする日心軌道面座標 [au,au,0] を求める
    this.prime = [this.a * (Math.cos(E) - this.e), this.b * Math.sin(E)]
    return [
      this.convertMatrix[0][0] * this.prime[0] +
        this.convertMatrix[0][1] * this.prime[1],
      this.convertMatrix[1][0] * this.prime[0] +
        this.convertMatrix[1][1] * this.prime[1],
      this.convertMatrix[2][0] * this.prime[0] +
        this.convertMatrix[2][1] * this.prime[1]
    ]
  }

  _rad(rad) {
    if (rad < 0) {
      return 2 * Math.PI + rad - Math.PI
    }

    return rad + Math.PI
  }

  getRad() {
    return this._rad(Math.atan2(this.point[1], this.point[0]))
  }

  /**
   * 反時計回りによる太陽から見た2惑星間の角度(rad)を求める
   * rad * 180 / Math.PI = 度数法による角度
   * 時計回りでの角度は、Math.abs(Math.PI * 2 - getPlanetRad(対象の惑星)) によって求められる
   *
   * @param planet
   * @returns {number}
   */
  getPlanetRad(planet) {
    const rad1 = this.getRad()
    const rad2 = planet.getRad()

    if (rad2 > rad1) {
      return rad2 - rad1
    } else {
      return Math.PI * 2 - rad1 + rad2
    }
  }
}

export default Planet
