require('material-design-lite/material')
require('./material.min.css')
require('./style.css')

import React from 'react'
import {render} from 'react-dom'
import {Provider, connect} from 'react-redux'
import {createStore} from 'redux'
import Root from './components/root.jsx'
import reducer from './reducers'
const store = createStore(reducer)

function mapStateToProps(state) {
  return state
}

const AppContainer = connect(mapStateToProps)(Root)

render(
  <Provider store={store}>
    <AppContainer />
  </Provider>,
  document.getElementById('app')
)
