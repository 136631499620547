import {connect} from 'react-redux'
import MainContent from '../components/main-content.jsx'

const mapStateToProps = (state) => {
  return {
    solarSystem: state.solarSystem
  }
}

export default connect(mapStateToProps)(MainContent)
