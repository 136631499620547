import {connect} from 'react-redux'
import NavigationDrawer from '../components/navigation-drawer.jsx'

const mapStateToProps = (state) => {
  return {
    solarSystem: state.solarSystem,
    planetRenderer: state.planetRenderer
  }
}

export default connect(mapStateToProps)(NavigationDrawer)
