import {connect} from 'react-redux'
import DataMenu from '../components/data-menu.jsx'

const mapStateToProps = (state) => {
  return {
    planetRenderer: state.planetRenderer,
    date: state.date
  }
}

export default connect(mapStateToProps)(DataMenu)
