import Planet from './Planet'

class SolarSystem {
  constructor() {
    this.mercury = new Planet(
      'Mercury',
      3.301e23,
      2440,
      [
        0.38709927, 0.20563593, 0.1222599479, 4.4025986843, 1.3518935764,
        0.8435309955, 0.00000037, 0.00001906, -0.0001038033, 2608.7903050105,
        0.0028008501, -0.0021876098
      ],
      [
        0.38709843, 0.20563661, 0.1222706869, 4.402622137, 1.3518922268,
        0.8436854966, 0.0, 0.00002123, -0.000103002, 2608.7903181787,
        0.0027820571, -0.0021317769
      ]
    )

    this.venus = new Planet(
      'Venus',
      4.869e24,
      6052,
      [
        0.72333566, 0.00677672, 0.0592482741, 3.1761344561, 2.296896356,
        1.3383157224, 0.0000039, -0.00004107, -0.0000137689, 1021.3285495824,
        0.0000468322, -0.0048466778
      ],
      [
        0.72332102, 0.00676399, 0.0593023688, 3.1761450851, 2.2997777192,
        1.3381895772, -0.00000026, -0.00005107, 0.0000075911, 1021.3285533403,
        0.0009912856, -0.0047602414
      ]
    )

    this.earth = new Planet(
      'Earth',
      5.972e24,
      6378,
      [
        1.00000261, 0.01671123, -0.0000002672, 1.7534375571, 1.796601474, 0.0,
        0.00000562, -0.00004392, -0.0002259622, 628.3075779009, 0.0056421894,
        0.0
      ],
      [
        1.00000018, 0.01673163, -0.0000094852, 1.7534784686, 1.7964684262,
        -0.0892317712, -0.00000003, -0.00003661, -0.0002333816, 628.3075886082,
        0.0055493197, -0.0042104072
      ]
    )

    this.mars = new Planet(
      'Mars',
      6.39e23,
      3397,
      [
        1.52371034, 0.0933941, 0.0322832054, -0.0794723815, -0.4178951712,
        0.8649771297, 0.00001847, 0.00007882, -0.0001419181, 334.0613016814,
        0.0077564331, -0.0051063697
      ],
      [
        1.52371243, 0.09336511, 0.0323203333, -0.0797289378, -0.4174382135,
        0.8676591934, 0.00000097, 0.00009149, -0.000126494, 334.0612433427,
        0.0078930116, -0.0046866333
      ]
    )

    this.jupiter = new Planet(
      'Jupiter',
      1.898e27,
      71492,
      [
        5.202887, 0.04838624, 0.0227660215, 0.6003311379, 0.2570604668,
        1.753600526, -0.00011607, -0.00013253, -0.0000320641, 52.9663118914,
        0.0037092903, 0.0035725329
      ],
      [
        5.20248019, 0.0485359, 0.0226650928, 0.59925516, 0.2491449206,
        1.7504400393, -0.00002864, 0.00018026, -0.0000563216, 52.9690623526,
        0.0031763589, 0.0022732249, -0.0000021733, 0.0010583781, -0.0062195572,
        0.6693555848
      ]
    )

    this.saturn = new Planet(
      'Saturn',
      5.683e26,
      60268,
      [
        9.53667594, 0.05386179, 0.0433887433, 0.8718660372, 1.6161553102,
        1.983783543, -0.0012506, -0.00050991, 0.0000337911, 21.3365387887,
        -0.0073124437, -0.0050383805
      ],
      [
        9.54149883, 0.05550825, 0.0435327181, 0.8739860722, 1.6207364909,
        1.9833919354, -0.00003065, -0.00032044, 0.0000788835, 21.3299296672,
        0.0094561028, -0.0043659415, 0.0000045202, -0.0023447572, 0.0152402407,
        0.6693555848
      ]
    )
    this.uranus = new Planet(
      'Uranus',
      8.681e25,
      25559,
      [
        19.18916464, 0.04725744, 0.0134850741, 5.4670362664, 2.9837149918,
        1.291839044, -0.00196176, -0.00004397, -0.0000424009, 7.4784221716,
        0.0071218651, 0.0007401224
      ],
      [
        19.18797948, 0.0468574, 0.0134910682, 5.4838727899, 3.0095418175,
        1.2908891855, -0.00020455, -0.0000155, -0.000031443, 7.4786507766,
        0.001617394, 0.0010017665, 0.0000101807, -0.0170574253, 0.0030873557,
        0.133871117
      ]
    )
    this.neptune = new Planet(
      'Neptune',
      1.024e26,
      24766,
      [
        30.06992276, 0.00859048, 0.0308930865, -0.9620260019, 0.784783149,
        2.3000686414, 0.00026291, 0.00005105, 0.0000061736, 3.8128367413,
        -0.005627197, -0.0000887786
      ],
      [
        30.06952752, 0.00895439, 0.0308932912, 5.3096911405, 0.8147473974,
        2.3001058656, 0.00006447, 0.00000818, 0.0000039095, 3.8129362232,
        0.0001762674, -0.0001058197, -0.0000072166, 0.0119286828, -0.0017736991,
        0.133871117
      ]
    )

    this.pluto = new Planet(
      'Pluto',
      1.3e22,
      1150,
      [
        39.48211675, 0.2488273, 0.2991496443, 4.1700983975, 3.9107403406,
        1.9251668758, -0.00031596, 0.0000517, 0.0000008409, 2.5343542995,
        -0.0007091172, -0.0002065566
      ],
      [
        39.48686035, 0.24885238, 0.2991676306, 4.1707321576, 3.9112309473,
        1.925127484, 0.00449751, 0.00006016, 0.0000000874, 2.533876496,
        -0.0001690922, -0.0001413684, -0.0002203869
      ]
    )

    this.planets = [
      this.mercury,
      this.venus,
      this.earth,
      this.mars,
      this.jupiter,
      this.saturn,
      this.uranus,
      this.neptune,
      this.pluto
    ]
  }

  setDate(date) {
    for (let planet of this.planets) {
      planet.setDate(date)
    }
  }
}

// 太陽半径 [km]
// https://ja.wikipedia.org/wiki/%E5%A4%AA%E9%99%BD%E5%8D%8A%E5%BE%84
SolarSystem.SUN_RADIUS = 696000

// 天文単位 AU = 地球と太陽の平均距離
// https://ja.wikipedia.org/wiki/%E5%A4%A9%E6%96%87%E5%8D%98%E4%BD%8D
SolarSystem.AU = 149597870700

// 万有引力定数G
// https://ja.wikipedia.org/wiki/%E4%B8%87%E6%9C%89%E5%BC%95%E5%8A%9B%E5%AE%9A%E6%95%B0
SolarSystem.G = 6.6740831e-11

// 太陽中心重力定数 [m^3*s^-2]
// 万有引力定数G[m^3*kg^-1*s^-2]と太陽の質量M_Sun[kg]の積。
// https://ja.wikipedia.org/wiki/%E5%A4%AA%E9%99%BD%E8%B3%AA%E9%87%8F
SolarSystem.GM_SUN = 1.32712440018e20

// ガウスの引力定数
// 太陽を回る天体の軌道長半径a[au]の1.5乗と平均運動n[rad/day]の積。
// https://ja.wikipedia.org/wiki/%E3%82%AC%E3%82%A6%E3%82%B9%E5%BC%95%E5%8A%9B%E5%AE%9A%E6%95%B0
// Math.sqrt(GM_SUN / (AU * AU * AU)) * 86400
SolarSystem.K0 = 0.01720209895

export default SolarSystem
