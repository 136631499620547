import Julian from './Julian'

class AstronomyUtils {
  /**
   * 度分秒から度に変換する
   *
   * @param hours
   * @param minutes
   * @param seconds
   * @returns {number}
   */
  static hourToDeg(hours = 0, minutes = 0, seconds = 0) {
    return hours * 15 + 15 * (minutes / 60) + 0.25 * (seconds / 60)
  }

  /**
   * 度分秒からラジアンに変換する
   *
   * @param hours
   * @param minutes
   * @param seconds
   * @returns {*}
   */
  static hourToRad(hours = 0, minutes = 0, seconds = 0) {
    return this.degToRad(this.hourToDeg(hours, minutes, seconds))
  }

  /**
   * 度からラジアンに変換する
   *
   * @param deg
   * @param minutes
   * @param seconds
   * @returns {number}
   */
  static degToRad(deg, minutes = 0, seconds = 0) {
    let d = deg + minutes / 60 + seconds / 3600
    return (d * Math.PI) / 180
  }

  /**
   * ラジアンから度に変換する
   *
   * @param rad
   * @returns {number}
   */
  static radToDeg(rad) {
    return (rad * 180) / Math.PI
  }

  /**
   * Dateから黄道傾斜角を求める
   *
   * @param date
   * @returns {*}
   */
  static getEarthObliquity(date) {
    const t = Julian.dateToJulianCentury(date)

    // https://ja.wikipedia.org/wiki/%E9%BB%84%E9%81%93%E5%82%BE%E6%96%9C%E8%A7%92
    const sec =
      84381.406 -
      46.836769 * t -
      0.00059 * Math.pow(t, 2) +
      0.001813 * Math.pow(t, 3)

    return this.degToRad(0, 0, sec)
  }

  /**
   * 光年をAUに変換
   *
   * @param ly light-year
   * @return {number}
   */
  static lyToAu(ly) {
    return ly * AstronomyUtils.AU_LY
  }
}

AstronomyUtils.AU_LY = 63241.077084

export default AstronomyUtils
