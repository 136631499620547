import julian from 'julian'

class Julian {
  /**
   * datetime to julian day
   * datetime から ユリウス日への変換
   *
   * @param date
   * @returns {number}
   */
  static dateToJulian(date) {
    return Number(julian(date))
  }

  /**
   * datetime to julian century
   * datetime から ユリウス世紀への変換
   *
   * @param date
   * @returns {number}
   */
  static dateToJulianCentury(date) {
    return (this.dateToJulian(date) - 2451545.0) / 36525
  }
}

export default Julian
