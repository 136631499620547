import _ from 'lodash'
import uuid from 'uuid/v4'
import SolarSystem from './SolarSystem'

class Satellite {
  constructor(system, name, planet, v, rad1 = 0, rad2 = 0) {
    this.id = uuid()
    this.system = system
    this.name = name
    this.point = _.clone(planet.point)

    const vx = v * Math.sin(rad2) * Math.cos(rad1)
    const vy = v * Math.sin(rad2) * Math.sin(rad1)
    const vz = v * Math.cos(rad2)

    this.v = [vx, vy, vz]
    this.t = 0
  }

  _r(point1, point2 = [0.0, 0.0, 0.0]) {
    const x1 = point1[0]
    const y1 = point1[1]
    const z1 = point1[2]
    const x2 = point2[0]
    const y2 = point2[1]
    const z2 = point2[2]
    const x = x2 - x1
    const y = y2 - y1
    const z = z2 - z1
    return Math.sqrt(x * x + y * y + z * z)
  }

  _dot(x, y, z, i) {
    const point = [x, y, z]
    const day = 60 * 60 * 24
    const r = Math.sqrt(x * x + y * y + z * z)
    const GMd =
      (SolarSystem.GM_SUN / Math.pow(SolarSystem.AU, 3)) * Math.pow(day, 2)

    // 太陽の重力計算
    let p = (-GMd * point[i]) / Math.pow(r, 3)

    for (let planet of this.system.planets) {
      const g =
        ((SolarSystem.G * planet.mass) / Math.pow(SolarSystem.AU, 3)) *
        Math.pow(day, 2)
      let h =
        g *
        ((planet.point[i] - point[i]) /
          Math.pow(this._r(planet.point, point), 3) -
          planet.point[i] / Math.pow(this._r(planet.point), 3))
      p += h
    }

    return p
  }

  _impact() {
    if (
      (SolarSystem.SUN_RADIUS * 1000) / SolarSystem.AU >=
      this._r(this.point)
    ) {
      console.log(`Impact: Sun, ${this.name}`)
    }

    for (let planet of this.system.planets) {
      if (
        (planet.r * 1000) / SolarSystem.AU >=
        this._r(planet.point, this.point)
      ) {
        // nothing
      }
    }
  }

  clock(dt) {
    this.t += dt

    const x = this.point[0]
    const y = this.point[1]
    const z = this.point[2]
    const vx = this.v[0]
    const vy = this.v[1]
    const vz = this.v[2]

    const kx1 = vx * dt
    const kvx1 = this._dot(x, y, z, 0) * dt
    const ky1 = vy * dt
    const kvy1 = this._dot(x, y, z, 1) * dt
    const kz1 = vz * dt
    const kvz1 = this._dot(x, y, z, 2) * dt

    const kx2 = (vx + kvx1 / 2.0) * dt
    const kvx2 = this._dot(x + kx1 / 2.0, y + ky1 / 2.0, z + kz1 / 2.0, 0) * dt
    const ky2 = (vy + kvy1 / 2.0) * dt
    const kvy2 = this._dot(x + kx1 / 2.0, y + ky1 / 2.0, z + kz1 / 2.0, 1) * dt
    const kz2 = (vz + kvz1 / 2.0) * dt
    const kvz2 = this._dot(x + kx1 / 2.0, y + ky1 / 2.0, z + kz1 / 2.0, 2) * dt

    const kx3 = (vx + kvx2 / 2.0) * dt
    const kvx3 = this._dot(x + kx2 / 2.0, y + ky2 / 2.0, z + kz2 / 2.0, 0) * dt
    const ky3 = (vy + kvy2 / 2.0) * dt
    const kvy3 = this._dot(x + kx2 / 2.0, y + ky2 / 2.0, z + kz2 / 2.0, 1) * dt
    const kz3 = (vz + kvz2 / 2.0) * dt
    const kvz3 = this._dot(x + kx2 / 2.0, y + ky2 / 2.0, z + kz2 / 2.0, 2) * dt

    const kx4 = (vx + kvx3) * dt
    const kvx4 = this._dot(x + kx3, y + ky3, z + kz3, 0) * dt
    const ky4 = (vy + kvy3) * dt
    const kvy4 = this._dot(x + kx3, y + ky3, z + kz3, 1) * dt
    const kz4 = (vz + kvz3) * dt
    const kvz4 = this._dot(x + kx3, y + ky3, z + kz3, 2) * dt

    this.point[0] = x + (kx1 + 2 * kx2 + 2 * kx3 + kx4) / 6.0
    this.v[0] = vx + (kvx1 + 2 * kvx2 + 2 * kvx3 + kvx4) / 6.0
    this.point[1] = y + (ky1 + 2 * ky2 + 2 * ky3 + ky4) / 6.0
    this.v[1] = vy + (kvy1 + 2 * kvy2 + 2 * kvy3 + kvy4) / 6.0
    this.point[2] = z + (kz1 + 2 * kz2 + 2 * kz3 + kz4) / 6.0
    this.v[2] = vz + (kvz1 + 2 * kvz2 + 2 * kvz3 + kvz4) / 6.0

    this._impact()
  }
}

export default Satellite
