import Flatpickr from 'flatpickr'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import 'flatpickr/dist/flatpickr.css'

import {setDate} from '../actions'
import PlanetRenderer from '../planets/PlanetsRenderer'
import SolarSystem from '../planets/SolarSystem'

export default class NavigationDrawer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      date: new Date(),
      center: -1,
      t: 1,
      rate: 30,
      isPlay: false,
      isDisplayEllipse: true,
      isDisplayLine: true,
      playLabel: '自動時間送 開始',
      satelliteName: '',
      satelliteV: 30,
      satelliteDeg1: 90,
      satelliteDeg2: 0,
      satellites: [],
      zoomMode: PlanetRenderer.ZOOM_NORMAL
    }
    this.interval = null
  }

  play () {
    let isPlay = !this.state.isPlay
    if (isPlay) {
      this.setState({isPlay: isPlay, playLabel: '自動時間送 停止'})
      this.interval = setInterval(() => {
        let date = moment(this.flatpicker.selectedDates[0])
        date.add(parseFloat(this.state.t) * 24 * 60 * 60, 'seconds')
        this.flatpicker.setDate(date.toDate())
        this.props.planetRenderer.clockSatellite(this.state.t)
      }, this.state.rate)
    } else {
      this.setState({isPlay: isPlay, playLabel: '自動時間送 開始'})
      if (this.interval) {
        clearInterval(this.interval)
      }
    }
  }

  changeRate (e) {
    this.setState({rate: parseFloat(e.target.value)})
  }

  changeT (e) {
    this.setState({t: parseFloat(e.target.value)})
  }

  changeCenter (e) {
    let value = e.target.value
    this.setState({center: value})
    let matched = value.match(/^s-(.*)$/)

    if (matched) {
      this.props.planetRenderer.setCenter(
        matched[1],
        PlanetRenderer.CENTER_SATELLITE
      )
    } else {
      this.props.planetRenderer.setCenter(value)
    }
  }

  changeZoomMode (e) {
    let value = e.target.value
    this.setState({zoomMode: value})
    this.props.planetRenderer.setZoomMode(parseInt(value))
  }

  changeIsDisplayEllipse (e) {
    this.setState({isDisplayEllipse: e.target.checked})

    for (let planet of this.props.planetRenderer.planets) {
      planet.ellipse.visible = e.target.checked
    }
  }

  changeIsDisplayLine (e) {
    this.setState({isDisplayLine: e.target.checked})
    for (let planet of this.props.planetRenderer.planets) {
      planet.systemLine.visible = e.target.checked
    }
  }

  changeSatelliteName (e) {
    this.setState({satelliteName: e.target.value})
  }

  changeSatelliteV (e) {
    this.setState({satelliteV: parseFloat(e.target.value)})
  }

  changeSatellitDeg1 (e) {
    this.setState({satelliteDeg1: parseFloat(e.target.value)})
  }

  changeSatellitDeg2 (e) {
    this.setState({satelliteDeg2: parseFloat(e.target.value)})
  }

  shootSatellite () {
    let r = this.props.planetRenderer
    let params = this.state
    let date = moment(params.date)
    let name = params.satelliteName || `s-${date.format('YYYYMMDD')}`
    let rad1 = ((params.satelliteDeg2 - 180) * Math.PI) / 180
    let rad2 = (params.satelliteDeg1 * Math.PI) / 180
    let v = ((params.satelliteV * 1000) / SolarSystem.AU) * (60 * 60 * 24)
    let satellite = r.addSatellite(name, r.system.earth, v, rad1, rad2)
    this.setState({satellites: [...this.state.satellites, satellite]})
  }

  componentDidMount () {
    this.flatpicker = new Flatpickr(document.getElementById('datepicker'), {
      enableTime: true,
      defaultDate: new Date(),
      onChange: (selectedDate) => {
        this.setState({date: selectedDate[0]})
        this.props.dispatch(setDate(selectedDate[0]))
        this.props.planetRenderer.setDate(selectedDate[0])
      }
    })
  }

  goTop () {
    this.props.planetRenderer.moveCameraToTop()
  }

  render () {
    let planetOptions1 = [
      <option value='-1' key='-1'>
        Sun
      </option>
    ]
    for (let idx in this.props.solarSystem.planets) {
      planetOptions1.push(
        <option value={idx} key={idx}>
          {this.props.solarSystem.planets[idx].name}
        </option>
      )
    }
    for (let satellite of this.state.satellites) {
      planetOptions1.push(
        <option value={'s-' + satellite.id} key={'s-' + satellite.id}>
          {satellite.name}({satellite.id})
        </option>
      )
    }
    let zoomModeOptions = []
    zoomModeOptions.push(
      <option
        value={PlanetRenderer.ZOOM_NORMAL}
        key={PlanetRenderer.ZOOM_NORMAL}
      >
        標準
      </option>
    )
    zoomModeOptions.push(
      <option value={PlanetRenderer.ZOOM_HELIO} key={PlanetRenderer.ZOOM_HELIO}>
        地球暦
      </option>
    )

    return (
      <div className='drawer mdl-layout__drawer'>
        <nav className='mdl-navigation'>
          <div className='control'>
            <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label'>
              <input
                className='mdl-textfield__input'
                type='text'
                id='datepicker'
              />
              <label className='mdl-textfield__label'>日付</label>
            </div>

            <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label'>
              <input
                className='mdl-textfield__input'
                type='number'
                value={this.state.t}
                onChange={this.changeT.bind(this)}
              />
              <label className='mdl-textfield__label'>時間送り日数</label>
            </div>

            <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label'>
              <input
                className='mdl-textfield__input'
                type='number'
                value={this.state.rate}
                onChange={this.changeRate.bind(this)}
              />
              <label className='mdl-textfield__label'>画面更新頻度 ms</label>
            </div>

            <button
              className='mdl-button mdl-js-button'
              onClick={this.play.bind(this)}
            >
              {this.state.playLabel}
            </button>

            <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label select'>
              <select
                className='mdl-textfield__input'
                value={this.state.center}
                onChange={this.changeCenter.bind(this)}
              >
                {planetOptions1}
              </select>
              <label className='mdl-textfield__label'>中心位置</label>
            </div>

            <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label select'>
              <select
                className='mdl-textfield__input'
                value={this.state.zoomMode}
                onChange={this.changeZoomMode.bind(this)}
              >
                {zoomModeOptions}
              </select>
              <label className='mdl-textfield__label'>拡大モード</label>
            </div>

            <div>
              <label className='mdl-checkbox mdl-js-checkbox'>
                <input
                  type='checkbox'
                  className='mdl-checkbox__input'
                  checked={this.state.isDisplayEllipse}
                  onChange={this.changeIsDisplayEllipse.bind(this)}
                />
                <span className='mdl-checkbox__label'>惑星軌道表示</span>
              </label>
            </div>
            <div>
              <label className='mdl-checkbox mdl-js-checkbox'>
                <input
                  type='checkbox'
                  className='mdl-checkbox__input'
                  checked={this.state.isDisplayLine}
                  onChange={this.changeIsDisplayLine.bind(this)}
                />
                <span className='mdl-checkbox__label'>補助線表示</span>
              </label>
            </div>
            <div>
              <button
                className='mdl-button mdl-js-button'
                onClick={this.goTop.bind(this)}
              >
                カメラを上部に移動
              </button>
            </div>
            <div>
              <h3>探査機</h3>
              <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label'>
                <input
                  className='mdl-textfield__input'
                  value={this.state.satelliteName}
                  onChange={this.changeSatelliteName.bind(this)}
                />
                <label className='mdl-textfield__label'>探査機名</label>
              </div>
              <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label'>
                <input
                  className='mdl-textfield__input'
                  type='number'
                  value={this.state.satelliteV}
                  onChange={this.changeSatelliteV.bind(this)}
                />
                <label className='mdl-textfield__label'>初速(v) kg</label>
              </div>
              <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label'>
                <input
                  className='mdl-textfield__input'
                  type='number'
                  value={this.state.satelliteDeg1}
                  onChange={this.changeSatellitDeg1.bind(this)}
                />
                <label className='mdl-textfield__label'>θ 度</label>
              </div>
              <div className='mdl-textfield mdl-js-textfield mdl-textfield--floating-label'>
                <input
                  className='mdl-textfield__input'
                  type='number'
                  value={this.state.satelliteDeg2}
                  onChange={this.changeSatellitDeg2.bind(this)}
                />
                <label className='mdl-textfield__label'>φ 度</label>
              </div>
              <button
                className='mdl-button'
                onClick={this.shootSatellite.bind(this)}
              >
                発射
              </button>
            </div>
          </div>
        </nav>
      </div>
    )
  }
}

NavigationDrawer.propTypes = {
  solarSystem: PropTypes.object.isRequired,
  planetRenderer: PropTypes.object
}
